import React, { useEffect } from 'react';
import Helmet from 'react-helmet';
import BannerSection from '../../components/banner';
import Layout from '../../components/layout';
import SEO from '../../components/seo';
import { saveURLParamToSession } from '../../utils';
import style from '../styles/career.module.scss';
import bannerStyles from '../styles/banner.module.scss';
import CareerOGImage from '../../../static/logo/career-og.png';

const Careers = (props) => {
  const onClick = () => {
    // const ele = document.getElementById('widget');
    // window.scrollTo({
    //   top: ele.offsetTop,
    //   behavior: 'smooth'
    // });
    window.open('https://jobs.lever.co/klubworks', '_blank');
  };

  useEffect(() => {
    saveURLParamToSession();

    setTimeout(() => {
      const accessToken = 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6MzE1Njc4LCJ2ZXJzaW9uIjoxLCJ0eXBlIjoid2lkZ2V0VG9rZW4iLCJpYXQiOjE2MjA4MDQxNDF9.h5tumT7SIv5v2JGJJ43CRv1c-8Gxo26stGj_SP3kwM0';
      const config = {
        token: accessToken,
        companyId: '606ab8141580220019ab0d60',
        selector: '#widget'
      };
      if (window.MyLibrary) {
        const myWidget = window.MyLibrary.default.widgets.careerWidget.new(config);
        myWidget.render({});
      }

      if (window.Glider) {
        // eslint-disable-next-line no-unused-vars
        const gld = new window.Glider(document.querySelector('.glider'), {
          slidesToShow: 1,
          slidesToScroll: 1,
          dots: '.dots'
        });
      }
    }, 1000);
  }, []);

  return (
    // eslint-disable-next-line react/jsx-filename-extension
    <Layout>
      <SEO
        title="Careers at Klub"
        description="Careers at Klub"
        image={CareerOGImage}
      />
      <Helmet>
        <script src="https://cdn.jsdelivr.net/npm/glider-js@1/glider.min.js" />
        {/* <script src="https://d31au0f9bp1wfs.cloudfront.net/v1/widget.js" /> */}
        <link rel="stylesheet" href="https://cdn.jsdelivr.net/npm/glider-js@1/glider.min.css" />
      </Helmet>
      <div className={bannerStyles.customHeaderContainer}>
        <BannerSection
          heading="Opportunities at Klub"
          subHeading=""
          description="Are you a fintech geek who wants to be part of the growth stories of high-affinity brands in India and SEA?"
          buttons={[
            <button type="button" onClick={onClick} className="btn btnklub1">
              View Open Positions
            </button>
          ]}
          customStyle={{ customHeader: bannerStyles.centerHeader, customButton: bannerStyles.centerButtons }}
        />
      </div>

      <div>
        <div className={`container ${style.pageContent}`}>
          {props.children}
        </div>
      </div>

      {/* <div className="container">
        <div id="widget" className={style.openings} />
      </div> */}

    </Layout>
  );
};
export default Careers;
