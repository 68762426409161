/* eslint-disable no-nested-ternary,react/jsx-filename-extension */
import { graphql } from 'gatsby';
import React from 'react';
import '../common-styles/blog.scss';
import Careers from './app/careers';

const Blog = (props) => {
  const { data } = props;
  const { pages } = data || {};

  return (
    <Careers>
      <div className="container">
        {pages?.edges?.map((page) => <section
          className="content-body load-external-scripts"
          dangerouslySetInnerHTML={{ __html: page?.node?.html }}
        />)}
      </div>
    </Careers>
  );
};
export default Blog;

export const pageQuery = graphql`
  {
    pages: allGhostPage {
      edges {
        node {
          id
          slug
          title
          html
        }
      }
    }
  }
`;
