import PropTypes from 'prop-types';
import React from 'react';

import bannerStyles from './style.module.scss';

const BannerSection = ({ image, heading, subHeading, description, subDescription, buttons, isCustom, customStyle = {}, isHome, blogLink, headingLine3, headingLine4 }) => (
  <div className={bannerStyles.landingSection}>
    <div className={`${bannerStyles.overlay} overlay`} />
    <div className="container">
      <div className={`${bannerStyles.bannerContainer} ${bannerStyles.customBannerContainer}`}>
        <div className={`${bannerStyles.bannerHeader} ${customStyle.customHeader}`}>
          <div className={`bannerHeading ${bannerStyles.bannerHeading}`}>
            <span>{heading}</span>
            {
              (subHeading !== '') && (
                <>
                  <br />
                  <span>
                    {subHeading}
                    <span className={bannerStyles.lineThrough}>{headingLine3}</span>
                    {headingLine4}
                  </span>
                </>
              )
            }
          </div>
          <p className={bannerStyles.bannerDescription}>
            {description}
            {
              (subDescription !== '') && (
                <>
                  <br className={isCustom ? 'd-none d-md-block' : ''} />
                  {subDescription}
                </>
              )
            }
          </p>
          <div className={`${bannerStyles.headerButtons} ${customStyle.customButton} header-buttons ${isHome ? `${bannerStyles.homeHeaderBtn}` : ''}`}>
            {buttons.map((button) => button)}
          </div>
          {isHome && (
            <div className={bannerStyles.blogLink}>
              {blogLink.map((button) => button)}
            </div>
          )}
        </div>
        <div className={`${bannerStyles.bannerImageContainer} bannerImage`}>
          <img loading="eager" src={image} alt="" className={bannerStyles.bannerImage} />
        </div>
      </div>
    </div>
  </div>
);

BannerSection.propTypes = {
  image: PropTypes.string,
  heading: PropTypes.string,
  subHeading: PropTypes.string,
  description: PropTypes.string,
  subDescription: PropTypes.string,
  buttons: PropTypes.arrayOf(PropTypes.object),
  blogLink: PropTypes.arrayOf(PropTypes.object)
};

BannerSection.defaultProps = {
  image: '',
  heading: '',
  subHeading: '',
  description: '',
  subDescription: '',
  buttons: [],
  blogLink: []
};

export default BannerSection;
